import React, { useState } from 'react';
import Button from "../components/btn"
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler
} from 'reactstrap';
import Link from './link'
import Navigation from 'reactstrap-json-nav'
import navigation from '../data/navigation';
import logo from '../assets/images/logo-full-trasp.png'
import styled from 'styled-components'

let StyledNavbar = styled(props => <Navbar {...props}/>)`
  position: sticky;
  top: 0;
  z-index: 10;
`

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <StyledNavbar color="white" light expand="md">
        <Link to="/" className="navbar-brand">
          <img src={logo} alt="Logo" height="80px" width="auto" />
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Navigation
            json={navigation}
            link={Link}
            activeClassName="active"
          />
          <Button to="download/AssistenzaTC.exe" className="btn btn-primary ml-3">Assistenza</Button>
        </Collapse>
    </StyledNavbar>
  );
}

export default Header;
